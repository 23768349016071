<template>
    <AccountLayout>
        <template v-slot:title>
            <h2>Profile</h2>
        </template>
        <template v-slot:content>
            <div class="profile">
                <form>
                    <div class="profile-form">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="profile-form-account">
                                    <div class="profile-form-account-data">
                                        <div class="profile-form-account-data-avatar">
                                            <avatar :img="User.partnerProfile.avatar"/>
                                        </div>
                                        <div class="profile-form-account-data-title">
                                            <div class="name">
                                                {{User.login}}
                                            </div>
                                            <div class="subscribe">
                                                {{User.partnerProgramName}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="profile-form-data">
                                    <div class="input-group">
                                        <label class="input-group-title">Email</label>
                                        <div class="input-group-data">{{User.email}}</div>
                                    </div>
                                    <div class="input-group">
                                        <label class="input-group-title">Telegram</label>
                                        <div class="input-group-data">{{User.partnerProfile.telegramm}}</div>
                                    </div>
                                    <div class="input-group">
                                        <label class="input-group-title">Min pay</label>
                                        <div class="input-group-data">{{User.partnerProfile.minPayment}}</div>
                                    </div>
                                    <div class="input-group">
                                        <label class="input-group-title">Bitcoin address</label>
                                        <div class="input-group-data">{{User.partnerProfile.address}}</div>
                                    </div>

                                    <div class="buttons">
                                        <router-link :to="{ name: 'Edit Profile'}" class="btn-edit">
                                            Edit profile
                                        </router-link>
                                        <router-link :to="{ name: 'Change Password'}" class="btn-change-password">
                                            <img alt="Vue logo" src="~@/assets/img/lock.svg">
                                            Change password
                                        </router-link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </template>
    </AccountLayout>
</template>

<script>
    import AccountLayout from "../../components/AccountLayout";
    import Avatar from "../../components/Avatar";

    export default {
        name: "Profile",
        components: {Avatar, AccountLayout},
        computed: {
            User: {
                get() {
                    return this.$store.state.user;
                },
                set(value) {
                    this.$store.commit('setter', {user: value});
                },
            }
        },
    }
</script>

<style lang="scss">
    .profile {
        &-form {
            background: white;
            border-radius: 8px;
            border: 1px solid #DFE0EB;

            &-account {
                padding: 32px 48px;

                @media (max-width: 764px) {
                    padding: 30px 20px 0 20px;
                }

                &-data {
                    display: flex;
                    flex-direction: column;

                    @media (max-width: 764px) {
                        align-items: center;
                        justify-content: center;
                    }

                    &-avatar img {
                        -webkit-border-radius: 90px;
                        -moz-border-radius: 90px;
                        border-radius: 90px;
                        -khtml-border-radius: 90px;
                        border: 2px solid #02C076;
                        box-shadow: inset 0 0 0 3px white;
                        height: 100px;
                        max-width: 100px;
                        width: 100%;
                    }

                    &-title {
                        padding-top: 20px;

                        @media (max-width: 764px) {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }

                        .name {
                            font-size: 20px;
                            font-weight: 700;
                            padding-bottom: 8px;
                            line-height: 24px;
                        }

                        .subscribe {
                            color: #02C076;
                            font-size: 14px;
                            font-weight: 600;
                            letter-spacing: 0.2px;
                            line-height: 18px;
                        }
                    }
                }
            }

            &-data {
                padding: 40px 0 50px 0;

                @media (max-width: 764px) {
                    padding: 20px 20px 30px 20px;
                }

                .input-group {
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 40px;

                    &:last-child {
                        padding-bottom: 56px;
                    }

                    &-title {
                        color: #673DFD;
                        font-size: 14px;
                        padding-bottom: 12px;
                    }

                    &-data {
                        font-size: 14px;
                        display: flex;
                        flex-direction: row;

                        &-btc {
                            padding-left: 8px;
                            color: #9FA2B4;
                        }
                    }
                }

                .buttons {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    @media (max-width: 764px) {
                        justify-content: space-between;
                    }


                    .btn-edit {
                        background: #673DFD;
                        color: white;
                        padding: 14px 29px;
                        border: none;
                        border-radius: 4px;
                        font-size: 14px;
                        margin-right: 40px;

                        @media (max-width: 764px) {
                            margin-right: 0;
                            padding: 10px 20px;
                        }
                    }

                    .btn-change-password {
                        padding: 14px 29px;
                        color: #673DFD;
                        text-align: center;
                        font-size: 14px;
                        background: none;
                        border: none;
                        outline: none;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        @media (max-width: 764px) {
                            padding: 10px 12px;
                        }

                        img {
                            padding-right: 8px;
                        }
                    }
                }
            }
        }
    }
</style>
