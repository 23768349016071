<template>
  <LandingLayout>
    <template #landing>
      <form @submit.prevent="submitForm">
        <div class="login-form">
          <div class="banner">
            <div class="title">
              Login an account
            </div>
            <div class="desc">
              Get access to endless opportunities as a affiliate
            </div>
            <img
                class="img"
                src="../../../assets/img/png/loginImg2x.png"
                srcset="../../../assets/img/png/loginImg1x.png 1x,
                  ../../../assets/img/png/loginImg2x.png 2x,
                  ../../../assets/img/png/loginImg4x.png 4x"
                alt=""
            />
          </div>
          <div class="login-form_block">
            <div class="switcher">
              <router-link :to="{name: 'Registration'}" class="switcher--item"
              >
                Sign up
              </router-link>
              <div class="switcher--item switcher--item_active"
              >
                Sign in
                <div class="switcher--item_active--underline"></div>
              </div>
            </div>
            <div class="inputs">
              <div
                  class="input-state input-state-icon"
                  :class="{'input-state-error': error}"
              >
                <input
                    v-model.trim="$v.form.login.$model"
                    type="text"
                    :class="{ 'no-valid': $v.form.login.$error, 'valid': !$v.form.login.$error, 'active-input': form.password.length }"
                    required
                />
                <IconInputs
                    :icon="inputsSignIn.login.img"
                />
                <label>{{ inputsSignIn.login.input }}</label>
              </div>
              <div
                  class="input-state input-state-icon"
                  :class="{'input-state-error': error}"
              >
                <input
                    v-model="form.password"
                    id="password"
                    :type="isShowPassword"
                    required
                />
                <IconInputs
                    :icon="inputsSignIn.password.img"
                />
                <div @click="switchVisibility" class="showPassword">
                  <eye-password v-if="isShowPassword === 'password'"/>
                  <open-eye-password v-else/>
                </div>
                <label>{{ inputsSignIn.password.input }}</label>
              </div>
              <label class="error-text" v-if="error">{{ error }}</label>
              <button type="submit" :disabled="submitStatus" class="btn">
                Sign in
              </button>
              <div class="btn-forgot">
                Forgot password?
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
  </LandingLayout>
</template>

<script>
import LandingLayout from "@/components/LandingLayout.vue";
import {minLength, required} from "vuelidate/lib/validators";
import IconInputs from "@/components/IconInputs.vue";
import EyePassword from "@/assets/img/svg/eyePassword.vue";
import OpenEyePassword from "@/assets/img/svg/openEyePassword.vue";
import UserService from "@/services/user.service";

export default {
  name: "SignIn",
  components: {OpenEyePassword, EyePassword, IconInputs, LandingLayout},
  data() {
    return {
      tabSelect: 'SignIn',
      inputsSignIn: {
        login: {
          input: "Your login or email",
          value: '',
          img: 'Login'
        },
        password: {
          input: "Password",
          value: '',
          img: 'Password'
        },
      },
      form: {
        login: '',
        password: ''
      },
      checkbox: '',
      submitStatus: null,
      error: '',
      isShowPassword: 'password',
    }
  },
  validations: {
    form: {
      login: {
        required,
        minLength: minLength(1)
      },
      password: {
        required,
        minLength: minLength(6)
      },
    }
  },
  methods: {
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const credential = {
          login: this.form.login,
          password: this.form.password
        };

        UserService.login(credential)
            .then((result) => {
              if (result.status === 200) {
                const token = result.data.result.token;
                localStorage.token = token;
                this.$store.commit('setter', { token: token });
                this.error = '';
                this.$router.push({ name: 'Dashboard' });
              }
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                this.error = error.response.data.message.charAt(0).toUpperCase() + error.response.data.message.slice(1);
              } else {
                this.error = { message: error.message || 'Unexpected error occurred' };
              }
            });
      } else {
        console.log('Form validation failed');
      }
    },
    switchVisibility() {
      if (this.isShowPassword === 'password') {
        this.isShowPassword = 'text'
      } else {
        this.isShowPassword = 'password'
      }
    },
  }
}
</script>

<style lang="scss">
@import "../../../assets/styles/components/login-form";
@import "../../../assets/styles/components/input-state";
</style>
